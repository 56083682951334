import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateAuthContext, useStateHostContext } from '../context'
import { formatRupiah } from '../utils'
import { error, success } from '../notify';
import { FaArrowLeft, FaCheck, FaFileAlt, FaInfoCircle, FaListAlt, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { BsPencilFill } from 'react-icons/bs';
import { BeatLoader } from 'react-spinners';
import { useRef } from 'react';

const Admin_kendaraan_detail_peropd = () => {
  const direct = useNavigate();
  const { state } = useLocation()
  const { HOST } = useStateHostContext();
  const { STATE_AUTH } = useStateAuthContext();
  const [data_detail, set_data_detail] = React.useState([])
  const [loading, set_loading] = React.useState(false)
  const [lelang, set_lelang] = React.useState("")
  const [status_kondisi, set_status_kondisi] = React.useState("baik")
  const [file_status_kondisi, set_file_status_kondisi] = React.useState(null)
  const [status_barang, set_status_barang] = React.useState("tersedia")
  const [keterangan_pinjam_pakai, set_keterangan_pinjam_pakai] = React.useState("")
  const [file_status_barang, set_file_status_barang] = React.useState(null)
  const [file_penilian_lelang, set_file_penilaian_lelang] = React.useState(null)
  const [loading_status_kondisi, set_loading_status_kondisi] = React.useState(false)
  const [loading_status_barang, set_loading_status_barang] = React.useState(false)
  const [loading_status_penilaian, set_loading_status_penilaian] = React.useState(false)
  const ref_file_status_barang = useRef();



  const getDetailKendaraan = React.useCallback(async () => {
    set_loading(true)
    const param = {
      method: 'GET',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': state.id_opd
      }
    }
    const request = await fetch(`${HOST}/admin/kendaraan/detail/opd/${state.id_kendaraan}`, param)
    const respinse = await request.json()
    set_data_detail(respinse.d)
    set_loading(false)
  }, [HOST, STATE_AUTH, state])

  React.useEffect(() => {
    getDetailKendaraan()
    return () => { set_data_detail([]) }
  }, [getDetailKendaraan])

  const handleLelang = async (e) => {
    e.preventDefault()
    set_loading_status_penilaian(true)
    const formData = new FormData()
    formData.append('STATUS_LELANG', lelang)
    formData.append('FILE_PENILAIAN_LELANG', file_penilian_lelang)
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': state.id_opd
      },
      body: formData
    }

    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/update`, param)
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_penilaian(false)
    }
  }

  const handleHapusStatusPenilaian = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    set_loading_status_penilaian(true)
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_LELANG', data_detail[0].status_lelang)
    formData.append('NAMA_FILE', namaFile)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_barang('tersedia')
          set_file_status_barang(null)
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (error) {
      error({ msg: 'Masalah ditemukan : ' + error.message })
      set_loading_status_penilaian(false)
    }

  }

  const handleUpdateStatusKeLelang = async () => {
    set_loading_status_penilaian(true)
    const formData = new FormData()
    formData.append('STATUS_LELANG', 'lelang')
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    const param = {
      method: 'POST',
      headers: {
        'REST-TOKEN': STATE_AUTH.token,
        'REST-ID-OPD': state.id_opd
      },
      body: formData
    }

    try {
      const request = await fetch(`${HOST}/admin/kendaraan/lelang/update`, param)
      const response = await request.json()
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_penilaian(false)
        } else {
          error({ msg: response.m })
          set_loading_status_penilaian(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_penilaian(false)
    }
  }

  /**
   * --------------------------------------------------------------------
   * BATAS AWAL PENAMBAHAN MODUL BARU
   * --------------------------------------------------------------------
   */

  const handleUbahStatusKondisiKendaraan = async (e) => {
    e.preventDefault()
    set_loading_status_kondisi(true)
    if (status_kondisi === 'rusak_berat') {
      if (!file_status_kondisi) {
        error({ msg: 'Harus mengupload surat keterangan rusak berat dari Bengkel atau SKTJM Kepala OPD' })
        return set_loading_status_kondisi(false)
      }
    }

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_KONDISI', status_kondisi)
    formData.append('FILE_STATUS_KONDISI_RUSAK_BERAT', file_status_kondisi)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/kondisi/update`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_kondisi(false)
        } else {
          error({ msg: response.m })
          set_loading_status_kondisi(false)
        }
      } else {
        throw new Error(response.m)
      }

    } catch (err) {
      error({ msg: err.message })
      set_loading_status_kondisi(false)
    }

  }
  const handleHapusStatusKondisiKendaraan = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('NAMA_FILE', namaFile)
    set_loading_status_kondisi(true)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/kondisi/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_kondisi('baik')
          set_file_status_kondisi(null)
          set_loading_status_kondisi(false)
        } else {
          error({ msg: response.m })
          set_loading_status_kondisi(false)
        }
      }
    } catch (err) {
      error({ msg: 'Masalah ditemukan : ' + err.message })
      set_loading_status_kondisi(false)
    }

  }
  const handleUbahStatusBarangKendaraan = async (e) => {
    e.preventDefault()
    set_loading_status_barang(true)
    if (status_barang === 'hilang') {
      if (!file_status_barang) {
        error({ msg: 'Harus mengupload surat keterangan hilang dari Kepolisian atau SKTJM Kepala OPD' })
        return set_loading_status_barang(false)
      }
    }

    if (status_barang === "pinjam_pakai") {
      if (!keterangan_pinjam_pakai) {
        error({ msg: 'Harus mengisi keterangan pinjam pakai' })
        return
      }
      if (!file_status_barang) {
        error({ msg: 'Harus mengupload surat keterangan Pinjam Pakai' })
        return set_loading_status_barang(false)
      }
    }

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_BARANG', status_barang)
    formData.append('KETERANGAN_PINJAM_PAKAI', keterangan_pinjam_pakai)
    formData.append('FILE_STATUS_BARANG', file_status_barang)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/barang/update`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_loading_status_barang(false)
          set_keterangan_pinjam_pakai("")
          set_file_status_barang(null)
        } else {
          error({ msg: response.m })
          set_loading_status_barang(false)
        }
      } else {
        throw new Error(response.m)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading_status_barang(false)
    }

  }
  const handleHapusStatusBarangKendaraan = async (file) => {
    if (!window.confirm('Ingin mengubah status ini.?')) {
      return
    }
    set_loading_status_barang(true)
    const parts = file.split('/');
    const namaFile = parts.pop();

    const formData = new FormData()
    formData.append('ID_KENDARAAN', state.id_kendaraan)
    formData.append('STATUS_BARANG', data_detail[0].status_barang)
    formData.append('NAMA_FILE', namaFile)
    try {
      const request = await fetch(`${HOST}/admin/kendaraan/status/barang/hapus`, {
        method: 'POST',
        headers: {
          'REST-TOKEN': STATE_AUTH.token
        },
        body: formData
      })
      const response = await request.json();
      if (request.ok) {
        if (response.s) {
          success({ msg: response.m })
          getDetailKendaraan()
          set_status_barang('tersedia')
          set_file_status_barang(null)
          set_loading_status_barang(false)
        } else {
          error({ msg: response.m })
          set_loading_status_barang(false)
        }
      }
    } catch (error) {
      error({ msg: 'Masalah ditemukan : ' + error.message })
      set_loading_status_barang(false)
    }

  }

  /**
  * --------------------------------------------------------------------
  * BATAS AKHIR PENAMBAHAN MODUL BARU
  * --------------------------------------------------------------------
  */

  return (
    <main>
      <div className="container-fluid mt-3">
        <div className="row mt-3">
          <div className="col-md-12">
            <h3><strong className='d-flex justify-content-start align-items-center'><FaListAlt size={24} />&nbsp;DETAIL KENDARAAN</strong></h3>
          </div>
        </div>
        <hr />
        <button className='btn btn-sm btn-warning  me-1' onClick={() => { direct(-1, { state: { id_opd: state.id_opd, opd: state.opd } }) }}> <FaArrowLeft size={20} /> Kembali</button>
        <button className='btn btn-sm btn-primary' onClick={() => direct("/admin/kendaraan/detail/edit", { state: { ...data_detail[0], id_opd: state.id_opd, id_kendaraan: state.id_kendaraan, opd: state.opd } })}><BsPencilFill /> Ubah data</button>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <table className='tb-daftar-dinas'>
              <tbody>
                {
                  !loading
                    ?
                    data_detail.length !== 0 || data_detail[0]?.kode_barang
                      ?
                      <>
                        <tr>
                          <td colSpan={2} className='bg-secondary text-light'><span className='d-flex justify-content-start align-items-center'><FaInfoCircle size={18} />&nbsp; INFO KENDARAAN</span></td>
                        </tr>
                        <tr>
                          <td width='20%'>Kode Barang</td>
                          <td>{data_detail[0].kode_barang}</td>
                        </tr>
                        <tr>
                          <td>Nama Barang</td>
                          <td>{data_detail[0].nama_barang}</td>
                        </tr>
                        <tr>
                          <td>Nomor Register</td>
                          <td>{data_detail[0].nomor_register}</td>
                        </tr>
                        <tr>
                          <td>Merk Type</td>
                          <td>{data_detail[0].merk_type}</td>
                        </tr>
                        <tr>
                          <td>Ukuran/CC</td>
                          <td>{data_detail[0].ukuran_cc}</td>
                        </tr>
                        <tr>
                          <td>Tahun Pembuatan</td>
                          <td>{data_detail[0].tahun_pembuatan}</td>
                        </tr>
                        <tr>
                          <td>Tahun Pembelian</td>
                          <td>{data_detail[0].tahun_pembelian}</td>
                        </tr>
                        <tr>
                          <td>Warna</td>
                          <td>{data_detail[0].warna}</td>
                        </tr>
                        <tr>
                          <td>Bahan Bakar</td>
                          <td>{data_detail[0].bahan_bakar}</td>
                        </tr>
                        <tr>
                          <td>Nomor Rangka</td>
                          <td>{data_detail[0].no_rangka}</td>
                        </tr>
                        <tr>
                          <td>Nomor Mesin</td>
                          <td>{data_detail[0].no_mesin}</td>
                        </tr>
                        <tr>
                          <td>Nomor Polisi</td>
                          <td>{data_detail[0].no_polisi}</td>
                        </tr>
                        <tr>
                          <td>Nomor BPKB</td>
                          <td>{data_detail[0].no_bpkb}</td>
                        </tr>
                        <tr>
                          <td>Harga Pembelian</td>
                          <td>{formatRupiah(data_detail[0].harga)}</td>
                        </tr>
                        <tr>
                          <td>Jenis</td>
                          <td>{data_detail[0].jenis}</td>
                        </tr>
                        <tr>
                          <td>Sub Jenis</td>
                          <td>{data_detail[0].sub_jenis}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className='bg-secondary text-light'><span className='d-flex justify-content-start align-items-center'><FaInfoCircle size={18} />&nbsp; INFO PENGGUNA</span></td>
                        </tr>
                        <tr>
                          <td>Nip</td>
                          <td>{data_detail[0].nip ? data_detail[0].nip : '(Tidak Tersedia)'}</td>
                        </tr>
                        <tr>
                          <td>Nama Pengguna</td>
                          <td>{data_detail[0].nama ? data_detail[0].nama : '(Tidak Tersedia)'}</td>
                        </tr>
                        <tr>
                          <td>Jabatan</td>
                          <td>{data_detail[0].jabatan ? data_detail[0].jabatan : '(Tidak Tersedia)'}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className='bg-secondary text-light'><span className='d-flex justify-content-start align-items-center'><FaInfoCircle size={18} />&nbsp; INFO PKB</span></td>
                        </tr>
                        <tr>
                          <td>Pkb</td>
                          <td>{formatRupiah(data_detail[0].rp_pkb)}</td>
                        </tr>
                        <tr>
                          <td>Tanggal Jatuh Tempo STNK</td>
                          <td>{data_detail[0].tgl_jth_tmp_stnk}</td>
                        </tr>
                        <tr>
                          <td>Tanggal Jatuh Tempo PKB</td>
                          <td>{data_detail[0].tgl_jth_tmp_pkb}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} className='bg-info text-dark'><span className='d-flex justify-content-start align-items-center'><FaInfoCircle size={18} />&nbsp; INFO SURAT BPKB DAN STNK</span></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="card">
                                  <div className="card-header">BPKB</div>
                                  <div className="card-body">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                      <a className='btn btn-success btn-sm' href={data_detail[0].file_bpkb} target={"_blank"} rel="noreferrer"><i className='fa fa-file'></i> Preview File</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="card">
                                  <div className="card-header">STNK</div>
                                  <div className="card-body">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                      <a className='btn btn-success btn-sm' href={data_detail[0].file_stnk} target={"_blank"} rel="noreferrer"><i className='fa fa-file'></i> Preview File</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className='bg-info text-dark'><span className='d-flex justify-content-start align-items-center'><FaInfoCircle size={18} />&nbsp; INFO FOTO KENDARAAN</span></td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      {
                                        data_detail.file_fotken.length !== 0
                                          ?
                                          data_detail.file_fotken.map((val, key) => {
                                            return (<div className="col-md-4" key={key}>
                                              <div className="card" >
                                                <img src={val.file_gambar} alt='meload gambar...' className='card-img-top' />
                                                <div className="card-footer">
                                                  {val.nama_gambar}
                                                </div>
                                              </div>
                                            </div>)
                                          })
                                          :
                                          <span className='text-center'>Tidak ada file gambar.</span>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className='bg-warning text-dark'><FaInfoCircle size={18} />&nbsp; INFO STATUS</td>
                        </tr>

                        {
                          data_detail[0].status_barang === 'hilang' || data_detail[0].status_barang === 'pinjam_pakai'
                            ?
                            <tr>
                              <td colSpan={2}>
                                <div className="card">
                                  <div className="card-body">
                                    <label htmlFor="status_kondisi">Status Kendaraan</label>
                                    <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_barang} disabled />
                                    <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                      {
                                        data_detail[0].status_barang === "hilang"
                                          ?
                                          <>
                                            <button className='btn btn-success' onClick={() => window.open(data_detail[0].file_sk_status_barang_hilang, "_blank")}><FaFileAlt /> Lihat surat keterangan hilang dari Kepolisian / SKTJM Kepala OPD</button>
                                            {
                                              loading_status_barang
                                                ?
                                                <button className="btn btn-danger" type="button" disabled>
                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                  <span className="visually-hidden" role="status">Loading...</span>
                                                </button>
                                                :
                                                <button className='btn btn-danger' onClick={handleHapusStatusBarangKendaraan.bind(this, data_detail[0].file_sk_status_barang_hilang)}><FaTimesCircle /></button>
                                            }
                                          </>
                                          :
                                          <>
                                            <button className='btn btn-success' onClick={() => window.open(data_detail[0].file_sk_status_pinjam_pakai, "_blank")}><FaFileAlt /> Lihat surat keterangan Pinjam Pakai</button>
                                            {
                                              loading_status_barang
                                                ?
                                                <button className="btn btn-danger" type="button" disabled>
                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                  <span className="visually-hidden" role="status">Loading...</span>
                                                </button>
                                                :
                                                <button className='btn btn-danger' onClick={handleHapusStatusBarangKendaraan.bind(this, data_detail[0].file_sk_status_pinjam_pakai)}><FaTimesCircle /></button>
                                            }
                                          </>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            :
                            <tr>
                              <td colSpan={2}>
                                <div className="card">
                                  <div className="card-body">
                                    <form onSubmit={handleUbahStatusBarangKendaraan}>
                                      <div className="form-group">
                                        <label htmlFor="status_kondisi">Status Kendaraan</label>
                                        <select name="status_lelang" id="status_kondisi" className='form-select form-select-sm mb-2' value={status_barang} onChange={e => { set_status_barang(e.target.value); ref_file_status_barang.current.value = null }} required>
                                          <option>--Pilih--</option>
                                          <option value="tersedia">Tersedia</option>
                                          <option value="hilang">Hilang</option>
                                          <option value="pinjam_pakai">Pinjam Pakai</option>
                                        </select>
                                        {status_barang === "hilang" || status_barang === "pinjam_pakai"
                                          ?
                                          <>
                                            {status_barang === "pinjam_pakai" &&
                                              <>
                                                <label htmlFor="keterangan_pinjam_pakai">Keterangan Pinjam Pakai</label>
                                                <input type="text" className='form-control form-control-sm mb-1' value={keterangan_pinjam_pakai} onChange={e => set_keterangan_pinjam_pakai(e.target.value)} placeholder='Masukan keterangan disini' required />
                                              </>
                                            }
                                            <input type='file' className='form-control form-control-sm' ref={ref_file_status_barang} onChange={e => set_file_status_barang(e.target.files[0])} required />
                                            {status_barang === "hilang" && <small className='text-muted'>Silahkan upload surat keterangan dari Kepolisian / SKTJM Kepala OPD</small>}
                                            {status_barang === "pinjam_pakai" && <small className='text-muted'>Silahkan upload Surat Pinjam Pakai</small>}
                                            <hr />
                                          </>
                                          :
                                          null
                                        }
                                        {loading_status_barang
                                          ?
                                          <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                            <span role="status"> Mohon tunggu...</span>
                                          </button>
                                          :
                                          <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                        }
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </td>
                            </tr>
                        }

                        {
                          data_detail[0].status_kondisi === 'rusak_berat'
                            ?
                            <tr>
                              <td colSpan={2}>
                                <div className="card">
                                  <div className="card-body">
                                    <label htmlFor="status_kondisi">Status Kondisi Kendaraan</label>
                                    <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_kondisi} disabled />
                                    <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                      <button className='btn btn-success' onClick={() => window.open(data_detail[0].file_sk_status_kondisi_rusak_berat, "_blank")}><FaFileAlt /> Lihat surat keterangan Bengkel / SKTJM Kepala OPD</button>
                                      {
                                        loading_status_kondisi
                                          ?
                                          <button className="btn btn-danger" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                            <span className="visually-hidden" role="status">Loading...</span>
                                          </button>
                                          :
                                          <button className='btn btn-danger' onClick={handleHapusStatusKondisiKendaraan.bind(this, data_detail[0].file_sk_status_kondisi_rusak_berat)}><FaTimesCircle /></button>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            :
                            <tr>
                              <td colSpan={2}>
                                <div className="card">
                                  <div className="card-body">
                                    <form onSubmit={handleUbahStatusKondisiKendaraan}>
                                      <div className="form-group">
                                        <label htmlFor="status_kondisi">Status Kondisi Kendaraan</label>
                                        <select name="status_lelang" id="status_kondisi" className='form-select form-select-sm mb-2' value={status_kondisi} onChange={e => set_status_kondisi(e.target.value)} required>
                                          <option>--Pilih--</option>
                                          <option value="baik">Baik</option>
                                          <option value="rusak_ringan">Rusak Ringan</option>
                                          <option value="rusak_berat">Rusak Berat</option>
                                        </select>
                                        {status_kondisi === "rusak_berat"
                                          ?
                                          <>
                                            <input type='file' className='form-control form-control-sm' onChange={e => set_file_status_kondisi(e.target.files[0])} required />
                                            <small className='text-muted'>Silahkan upload surat keterangan dari Bengkel / SKTJM Kepala OPD</small>
                                            <hr />
                                          </>
                                          :
                                          null
                                        }
                                        {
                                          loading_status_kondisi
                                            ?
                                            <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                              <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                              <span role="status"> Mohon tunggu...</span>
                                            </button>
                                            :
                                            <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                        }
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </td>
                            </tr>
                        }

                        {
                          data_detail[0].status_lelang !== 'lelang'
                            ?
                            data_detail[0].status_lelang === "penilaian"
                              ?
                              <tr>
                                <td colSpan={2}>
                                  <div className="card">
                                    <div className="card-body">
                                      <label htmlFor="status_kondisi">Status Lelang</label>
                                      <input type="text" className='form-control form-control-sm mb-2' value={data_detail[0].status_lelang} disabled />
                                      <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                                        <button className='btn btn-success' onClick={() => window.open(data_detail[0].file_sk_penilaian_lelang, "_blank")}><FaFileAlt /> Lihat surat keterangan Penilaian</button>
                                        {
                                          loading_status_penilaian
                                            ?
                                            <button className="btn btn-danger" type="button" disabled>
                                              <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                              <span className="visually-hidden" role="status">Loading...</span>
                                            </button>
                                            :
                                            <>
                                              <button className='btn btn-danger' onClick={handleHapusStatusPenilaian.bind(this, data_detail[0].file_sk_penilaian_lelang)} ><FaTimesCircle /></button>
                                              <button className='btn btn-info' onClick={handleUpdateStatusKeLelang.bind(this, data_detail[0].file_sk_penilaian_lelang)} title='Lanjut ke tahap lelang'><FaCheck /></button>
                                            </>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              :
                              <>
                                <tr>
                                  <td colSpan={2}>
                                    <div className="card">
                                      <div className="card-body">
                                        <form onSubmit={handleLelang}>
                                          <div className="form-group">
                                            <label htmlFor="status_lelang">Status Kendaraan Lelang</label>
                                            <select name="status_lelang" id="status_lelang" className='form-select form-select-sm' value={lelang} onChange={e => set_lelang(e.target.value)}>
                                              <option>--Pilih--</option>
                                              <option value="penilaian">Penilaian</option>
                                              {/* <option value="lelang">Lelang</option> */}
                                            </select>
                                            {lelang === "penilaian"
                                              ?
                                              <>
                                                <input type='file' className='form-control form-control-sm mt-2' onChange={e => set_file_penilaian_lelang(e.target.files[0])} required />
                                                <small className='text-muted'>Silahkan upload surat keterangan penilaian</small>
                                                <hr />
                                              </>
                                              :
                                              null
                                            }
                                            {
                                              loading_status_penilaian
                                                ?
                                                <button className="btn btn-primary btn-sm my-2" type="button" disabled>
                                                  <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                                  <span role="status"> Mohon tunggu...</span>
                                                </button>
                                                :
                                                <button type="submit" className='btn btn-primary btn-sm my-2'> Ubah</button>
                                            }
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            :
                            <>
                              <tr>
                                <td colSpan={2}>
                                  <div className="card">
                                    <div className="card-body">
                                      <h4>{data_detail[0].status_lelang === "lelang" ? 'Kendaraan Ini Dilelang' : ''}</h4>
                                      {
                                        loading_status_penilaian
                                          ?
                                          <button className="btn btn-danger btn-sm my-2" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                                            <span role="status"> Mohon tunggu...</span>
                                          </button>
                                          :
                                          <button type="button" onClick={handleHapusStatusPenilaian.bind(this, data_detail[0].file_sk_penilaian_lelang)} className='btn btn-danger btn-sm my-2'><FaTimes /> BATAL LELANG</button>
                                      }
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                        }
                      </>
                      :
                      <>
                        <tr>
                          <td colSpan={2} className='text-center'>(Data tidak ditemukan.)</td>
                        </tr>
                      </>
                    :
                    <>
                      <tr>
                        <td colSpan={2} className='text-center d-flex justify-content-center align-items-center'><BeatLoader /></td>
                      </tr>
                    </>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Admin_kendaraan_detail_peropd